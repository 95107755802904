import React from 'react'
import Link from 'gatsby-link'

import logo from '../images/logo.svg'
import circleDeco from '../images/circle-deco.png'

const Header = ({ title, topics, booklets }) => {
  const topicListItems = topics.map(t => {
    return <li key={t.id}><Link to={t.fields.path}>{t.title}</Link></li>;
  });

  const bookletListItems = booklets.map(b => {
    return <li key={b.id}><Link to={b.fields.path}>{b.title}</Link></li>;
  });

  return (
    <div id="header">
      <nav>
        <div id="logo">
          <Link to="/"><img src={logo} alt={title} /></Link>
        </div>
        <div id="pagenav" className="nav">
          <ul>
            <li><Link to="/" activeClassName="active">Home</Link></li>
            <li><Link to="/about-us" activeClassName="active">About Us</Link></li>
            <li>
              <a style={{ cursor: 'default' }}>Topics</a>
              <ul>
                {topicListItems}
              </ul>
            </li>
            <li>
              <Link to="/booklets" activeClassName="active">Booklets</Link>
              <ul>
                {bookletListItems}
              </ul>
            </li>
            <li><Link to="/translations" activeClassName="active">Translations</Link></li>
            <li><Link to="/faq" activeClassName="active">FAQ</Link></li>
            <li><Link to="/helpful-sites" activeClassName="active">Helpful Sites</Link></li>
            <li><Link to="/contact-us" activeClassName="active">Contact Us</Link></li>
          </ul>
        </div>
      </nav>
      <h1 className="slogan">Answers for Muslims from the book of the prophets</h1>
      <img src={circleDeco} alt="circle deco" className="circle-deco" />
    </div>
  );
}

export default Header
