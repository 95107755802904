import React from 'react'

const Footer = () => {
  return <div id="footer">
    <div id="copyright">
      <p>© 2018 Open Book Ministries. All Rights Reserved.</p>
    </div>
  </div>
}

export default Footer
