import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'
import Header from './header'
import Footer from './footer'
import '../styles/reset.css'
import '../styles/site.scss'
import '../fonts/stylesheet.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          allContentfulTopic {
            edges {
              node {
                ...topicDetail
              }
            }
          }
          allContentfulBooklet {
            edges {
              node {
                ...bookletSummary
              }
            }
          }
        }
      `}
    render={data => {
      const topics = data.allContentfulTopic.edges
        .map(({ node }) => node)
        .filter(node => node.booklet != null)
        .sort((a, b) => a.ordinal - b.ordinal);

      const booklets = data.allContentfulBooklet.edges
        .map(({ node }) => node)
        .sort((a, b) => a.ordinal - b.ordinal)

      return (
        <div id="container">
          <Helmet title={data.site.siteMetadata.title} />
          <Header title={data.site.siteMetadata.title} topics={topics} booklets={booklets} />
          {/* <Header title={"TBD THIS NEEDS TO BE FIXED"} topics={topics} booklets={booklets} /> */}

          <div id="content">
            <div id="main">
              {children}
            </div>
          </div>
          <Footer topics={topics} />
        </div>
      );
    }}
  />
)

export default Layout